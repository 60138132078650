import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { CustomerSubscriptionSummary } from '@orus.eu/backend/src/views/subscriptions/subscription-view'
import { TechnicalError } from '@orus.eu/error'
import {
  Button,
  EmptyState,
  RowButtonV2,
  RowContainerV2,
  Text,
  borderRadius,
  desktopMediaQuery,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
  useTranslate,
} from '@orus.eu/pharaoh'
import { ContentContainerAppClient, FlexColumn, PageTitle } from '@orus.eu/pharaoh/src/components/atoms'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback } from 'react'
import { v4 } from 'uuid'
import { trpc, trpcReact } from '../../../client'
import { capitalizeFirstLetter, formatDateTime } from '../../../lib/format'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { useNavigateToSubscriptionFunnel } from '../../../lib/hooks/use-navigate-to-subscription-funnel'
import { useSession } from '../../../lib/session'
import { SubscriptionStatusChip } from '../../molecules/subscription-status-chip'
import { SmallScreenMargin } from '../../templates/small-screen-margin'
import { getUserContractsByActivitiesAndAddresses } from '../contracts-by-activity-and-address'
import { productsShortNames } from '../subscription-v2/elements/quote-page-body-element/product-information-util'
import { ContractsByActivitiesAndAddresses } from './components/ContractsByActivitiesAndAddresses'
import { RequestContractsUpdateDialog } from './components/RequestContractsUpdateDialog'
import { onRequestContractUpdateTally } from './contrat-page.util'

const ContractsPage = memo(function ContractsPage(): JSX.Element {
  const translate = useTranslate()
  const navigate = useNavigate()
  const { user } = useSession()

  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()
  const [subscriptions] = trpcReact.subscriptions.listMyUnsignedSubscriptions.useSuspenseQuery()
  const navigateToSubscriptionFunnel = useNavigateToSubscriptionFunnel()

  const userContractsByActivitiesAndAddresses = getUserContractsByActivitiesAndAddresses(contracts)

  const {
    show: showRequestContractsUpdateDialog,
    hide: hideRequestContractsUpdateDialog,
    visible: isRequestContractsUpdateDialogVisible,
  } = useDialogVisibility('request-contracts-update')

  const onRequestContractsUpdate = useCallback(() => {
    const hasMultipleContracts = contracts.length > 1
    const hasMultipleProducts = contracts.some(
      (contract) =>
        contract.productsInformation.filter((productInformation) => productInformation.product !== 'rcph-pj').length >
        1,
    )

    if (hasMultipleContracts || hasMultipleProducts) {
      showRequestContractsUpdateDialog()
    } else {
      onRequestContractUpdateTally(contracts[0], user)
    }
  }, [showRequestContractsUpdateDialog, contracts, user])

  const onRequestContratUpdate = useAsyncCallback(
    async (subscriptionId: string) => {
      const contract = contracts.find((contract) => contract.subscriptionId === subscriptionId)
      if (contract) {
        onRequestContractUpdateTally(contract, user)
      }
    },
    [contracts, user],
  )

  const onContractClick = useCallback(
    (subscriptionId: string, _productName: string) => {
      void navigate({ to: '/contract/$subscriptionId', params: { subscriptionId } })
    },
    [navigate],
  )

  const createSubscription = useAsyncCallback(async () => {
    const embeddingPartner = contracts.length > 0 ? contracts[0].embeddingPartner : undefined

    const result = await trpc.selfOnboarding.createSubscriptionAsExternalUser.mutate({
      idempotencyKey: v4(),
      embeddingPartner: embeddingPartner,
    })

    if (result.type === 'failure') {
      throw new TechnicalError('Error while creating subscription as external user', {
        context: { err: result.problem },
      })
    }
    navigateToSubscriptionFunnel(result.output.subscriptionId)
  }, [navigateToSubscriptionFunnel, contracts])

  if (contracts.length === 0) {
    return (
      <SmallScreenMargin>
        <Text variant="body1">Vous n&apos;avez pas de contrat actif.</Text>
      </SmallScreenMargin>
    )
  }

  return (
    <AllPageHeight>
      {isRequestContractsUpdateDialogVisible && (
        <RequestContractsUpdateDialog
          userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
          onClose={hideRequestContractsUpdateDialog}
          onRequestContratUpdate={onRequestContratUpdate}
        />
      )}
      <PageTitle
        title={translate('my_contracts')}
        appendRightOnDesktop={
          <StyledButtonsContainer>
            <Button variant="secondary" size="small" aria-label={'request-update'} onClick={onRequestContractsUpdate}>
              {translate('request_update')}
            </Button>
            <Button
              variant="primary"
              size="small"
              icon="plus-regular"
              avatarPosition="left"
              onClick={createSubscription}
            >
              {translate('new_contract')}
            </Button>
          </StyledButtonsContainer>
        }
      />

      <ContentContainerAppClientWithMobileButtonOnBottom>
        <ContractsByActivitiesAndAddresses
          userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
          hideLegalProtection
          displayContractNumber
          onContractClick={onContractClick}
          activityVariant="subtitle2"
        />

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[50]};
          `}
        >
          <SmallScreenMargin>
            <Text variant="subtitle2">{translate('my_quotes')}</Text>
          </SmallScreenMargin>

          {subscriptions.length === 0 ? (
            <EmptyState subtitle={translate('no_pending_quote')} icon="file-light" height="100px" />
          ) : (
            <GuaranteeListContainer>
              {subscriptions.map((subscription) => {
                return <SubscriptionRowButton key={subscription.id} subscription={subscription} />
              })}
            </GuaranteeListContainer>
          )}
        </div>
        <SmallScreenMargin>
          <OnlyVisibleOnMobileCTAContainer>
            <OnlyVisibleOnMobileCTA variant="secondary" onClick={onRequestContractsUpdate} size="large" fullWidth>
              {translate('request_update')}
            </OnlyVisibleOnMobileCTA>
            <OnlyVisibleOnMobileCTA
              variant="primary"
              icon="plus-regular"
              avatarPosition="left"
              onClick={createSubscription}
              size="large"
              fullWidth
            >
              {translate('new_contract')}
            </OnlyVisibleOnMobileCTA>
          </OnlyVisibleOnMobileCTAContainer>
        </SmallScreenMargin>
      </ContentContainerAppClientWithMobileButtonOnBottom>
    </AllPageHeight>
  )
})

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[30]};
`

const AllPageHeight = styled(FlexColumn)`
  min-height: 100%;
  margin: 0;
  padding: 0;
  gap: 0;
  display: flex;
  flex-direction: column;
`

const ContentContainerAppClientWithMobileButtonOnBottom = styled(ContentContainerAppClient)`
  flex-grow: 1;

  & > :last-child {
    margin-top: auto;
  }
`

const OnlyVisibleOnMobileCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
  margin-bottom: ${spacing[60]};
`

const OnlyVisibleOnMobileCTA = styled(Button)`
  ${desktopMediaQuery} {
    display: none;
  }
`

const GuaranteeListContainer = styled(RowContainerV2)`
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;

  ${desktopMediaQuery} {
    border-radius: ${borderRadius[20]};
  }
`

type SubscriptionRowButtonProps = { subscription: CustomerSubscriptionSummary }
const SubscriptionRowButton = memo<SubscriptionRowButtonProps>(function SubscriptionRowButton({ subscription }) {
  const handleClick = useNavigateTo({
    to: '/subscribe/$subscriptionId/$stepId',
    params: { subscriptionId: subscription.id, stepId: 'quote' },
  })

  return (
    <RowButtonV2
      key={subscription.id}
      avatarRight={<SubscriptionStatusChip status={subscription.status} />}
      primaryText={getSubscriptionName(subscription)}
      secondaryText={subscription.activityName}
      tertiaryText={subscription.companyAddress}
      onClick={handleClick}
    />
  )
})

export default ContractsPage

function getSubscriptionName(subscription: CustomerSubscriptionSummary): string {
  if (subscription.selectedActivableProductInformationIds.length > 0) {
    return subscription.selectedActivableProductInformationIds
      .map((id) => productsShortNames[id])
      .map(capitalizeFirstLetter)
      .join(' + ')
  }

  return formatDateTime(subscription.creationTimestamp)
}
