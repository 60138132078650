import styled from '@emotion/styled'
import { Dialog, spacing, useTranslate, useUiContext } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import type { UserContractsByActivitiesAndAddresses } from '../../contracts-by-activity-and-address'
import { ContractsByActivitiesAndAddresses } from './ContractsByActivitiesAndAddresses'

type RequestContractsUpdateDialogProps = {
  userContractsByActivitiesAndAddresses: UserContractsByActivitiesAndAddresses[]
  onClose: () => void
  onRequestContratUpdate: (contractId: string) => void
}

export const RequestContractsUpdateDialog: FunctionComponent<RequestContractsUpdateDialogProps> = memo(
  function RequestContractsUpdateDialog({ userContractsByActivitiesAndAddresses, onClose, onRequestContratUpdate }) {
    const screenType = useUiContext()
    const translate = useTranslate()

    const onClick = (contractId: string) => {
      onClose()
      onRequestContratUpdate(contractId)
    }

    return (
      <Dialog
        title={translate('request_update')}
        style="base"
        size="medium"
        withoutContentMargin={screenType === 'mobile'}
        onClose={onClose}
      >
        <Container>
          <ContractsByActivitiesAndAddresses
            userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
            hideLegalProtection
            displayContractNumber
            hideCurrentProtectionStatus
            onContractClick={onClick}
            activityVariant="body2Medium"
          />
        </Container>
      </Dialog>
    )
  },
)

const Container = styled.div`
  width: 100%;
  gap: ${spacing[70]};
  display: flex;
  flex-direction: column;
`
